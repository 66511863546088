$primary: #6a54c1;
$secondary: #8d4b74;
$success: #30aa6c;
$info: #ec8552;
$warning: #e2ce2e;
$danger: #ef2c19;
$light: #eff7fa;
$dark: #2d2851;

$dark-900: #1e1f27;
$dark-800: #17181e;
$border-dark : #383838;
$light-500: rgba(239, 247, 250, 0.5);

$dark-disabled-text: $light-500;
$dark-disabled: #282828;

$input-bg: lighten($light, 13);
$input-focus-bg: lighten($light, 10);