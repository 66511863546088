:root {
    --header-height: 80px;
    --round-btn-size: 64px;
    --sidebar-width: 280px;
    --sidebar-shrink-width: 72px;
    --sidebar-mobile-width: calc(100dvw - var(--round-btn-size) - 32px);
    --round-btn-position: calc(-0.5 * calc(100dvw - var(--sidebar-mobile-width)) - var(--round-btn-size)/2);
    --control-height: 42px;
    --pagination-btn: 32px;
    --common-padding: 1rem 1.5rem;
    --common-border-radius: 0.25rem;
    --common-btn-padding: 0.5rem 0.75rem;
    --common-font-size: 14px;
    --bg-primary-light: #e2dcf9;
    --border-primary-light: #bfb8dc;
    --bg-secondary-light: #f2e3ed;
    --border-secondary-light: #e6d1df;
}

html,
body {
    min-height: 100dvh;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: var(--common-font-size);
    color: $light;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: white;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {}

::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid $border-dark;
}

.bg-dark {
    background-color: $dark-900 !important;
}

input {
    accent-color: $primary;
    caret-color: #fff;

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $dark-900 inset !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        background-color: $dark-900 !important;
        color: $light !important;
        -webkit-text-fill-color: $light !important;
        transition: background-color 5000s ease-in-out 0s;
    }

    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }

    &[type=file].custom-form-control {
        padding-top: 9px;
        padding-left: 15px;
    }
}

select {
    &.custom-form-control {
        padding-right: 2rem;
    }
}


input[type=password] {
    &:not(:placeholder-shown) {
        font-family: Password;
        font-weight: 900;
        font-size: 26px !important;
        letter-spacing: 1px;
        line-height: 1;
    }

    &:placeholder-shown {
        font-size: 1rem !important;
    }
}


//bootstrap overrides css
.btn {
    border-radius: var(--common-border-radius);
    transition: all 250ms ease-in-out;
    padding: var(--common-btn-padding);

    &.btn-create {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        min-height: var(--control-height);
    }
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
    color: $light;
}

.nav.nav-underline {
    border-color: $border-dark !important;
}

.dropdown-menu {
    background-color: $dark-900;
    border-color: $border-dark;

    .dropdown-item {
        color: $light;

        &:hover {
            background-color: $dark-800;
        }

    }
}

.pagination {
    margin: 1.5rem 0;

    .page-item {
        padding: 0 0.25rem;

        .page-link {
            border-radius: var(--common-border-radius);
            min-width: var(--pagination-btn);
            height: var(--pagination-btn);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $dark-800;
            border-color: $dark-800;
            color: $light;

            &:hover {
                background-color: $black;
            }
        }

        &.active {
            .page-link {
                background-color: $black;
            }
        }
    }
}

.table {
    --bs-table-color: $dark-800; //dark

    tr {
        th {
            @extend .bg-secondary;
            --bs-bg-opacity: 0.92;
            color: $light;
            background-color: $dark-800 !important;

            &:first-child {
                border-radius: var(--common-border-radius) 0 0 var(--common-border-radius);
            }

            &:last-child {
                border-radius: 0 var(--common-border-radius) var(--common-border-radius) 0;
            }

            // height: var(--control-height);
            border: none;
            font-weight: 500;
        }

        th,
        td {
            white-space: nowrap;
            vertical-align: middle;
            height: calc(var(--control-height) - 2px);

            &:first-child {
                padding-left: 1rem;
            }

            &:last-child {
                text-align: center;
                padding-right: 1rem;
            }
        }
    }

    tbody {
        tr {
            td {
                background-color: $dark-800;
                border-color: $border-dark;
                padding-top: 1px;
                padding-bottom: 1px;
                color: $light;

                &.data-not-found-block {
                    padding: 5rem;
                    font-size: 18px;
                }
            }

            &:first-child {
                td {
                    background-color: transparent !important;
                    border: none;
                    padding: 4px;

                    &:first-child {
                        border-top-left-radius: var(--common-border-radius);
                    }

                    &:last-child {
                        border-top-right-radius: var(--common-border-radius);
                    }
                }
            }

            &:nth-child(2) {
                td {
                    &:first-child {
                        border-top-left-radius: var(--common-border-radius);
                    }

                    &:last-child {
                        border-top-right-radius: var(--common-border-radius);
                    }
                }
            }

            &:last-child {
                td {
                    border: none;

                    &:first-child {
                        border-bottom-left-radius: var(--common-border-radius);
                    }

                    &:last-child {
                        border-bottom-right-radius: var(--common-border-radius);
                    }
                }
            }
        }
    }
}

.custom-form-label {
    color: $light;
}

.modal-header {
    border-color: $border-dark;
}

.modal-title {
    color: $light;
}

.modal-content {
    background-color: $dark-900;
    border-color: $border-dark;
    overflow: auto !important;
}

.modal-footer {
    border-color: $border-dark;
}

.btn-close {
    filter: invert(1);
}


//utils css
.flex-fill {
    min-width: 2px;
}

.flex-50 {
    flex: 1 1 calc(50% - 0.5rem);
}

.f-18 {
    font-size: 18px;
}

.error {
    color: $danger;
}

.dvh-100 {
    height: calc(100dvh - env(safe-area-inset-bottom));
}

.h-page {
    min-height: calc(100dvh - 70px);
    padding-top: 1.75rem;
}

.modal-90w {
    max-width: none !important;
    width: 90%;
}

.modal-lg {
    @include media-breakpoint-between(sm, lg) {
        --bs-modal-width: 80vw;
    }
}

.has-modal-above {
    z-index: 1050;
}

.cursor {
    cursor: pointer;
}

.iframe {
    position: relative;
    z-index: 99;
}

.size-42 {
    width: 42px;
    height: 42px;
}

.toggle-sidebar-btn {
    svg {
        width: 20px;

        path {
            fill: $light;
        }
    }
}

//custom css
.custom-form-control {
    @extend .form-control;
    border-radius: var(--common-border-radius);
    min-height: var(--control-height);
    background-color: $dark-800;
    border-color: $border-dark;
    color: $light;

    &.disabled,
    &:disabled {
        color: $dark-disabled-text;
        background-color: $dark-disabled;
    }

    &.disabled {
        display: flex;
        align-items: center
    }

    &:focus {
        background-color: $dark-800;
        border-color: $primary;
        color: $light;
    }
}

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $light-500 !important;
}

input::placeholder,
select::placeholder,
textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $light-500 !important;
    /* Firefox */
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $light-500 !important;
}

input::-ms-input-placeholder,
select::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $light-500 !important;
}

input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: $light-500 !important;
}

input:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
    /* Firefox 18- */
    color: $light-500 !important;
}


.custom-form-label {
    @extend .px-1;
    @extend .fw-medium;
    display: block;
    margin-bottom: 0.25rem;
}

.fill-primary {
    fill: $primary;
}

.fill-dark {
    fill: $dark;
}

.banner-image {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.canvas-wrapper {
    position: relative;
    height: 100dvh;

    &.canvas-wrapper-demo {
        z-index: 9;;
        position: absolute;
        width: 100%;
        height: 100%;

        .canvas-loader {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    // height: calc(100dvh - env(safe-area-inset-bottom));
    // padding-bottom: env(safe-area-inset-bottom);
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 300px;
    // height: 50dvh;
    // width: 100vw;
    // z-index: 9;
    // overflow: hidden;


    .canvas-parent {
        width: 100%;
        height: 100%;

        &.canvas-image {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .chat-wrapper {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 320px;
            max-height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0.5rem 1rem;
            overflow: auto;
            justify-content: flex-end;
            z-index: 1000;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            &.expandView {
                @include media-breakpoint-down(md) {
                    top: 58px;

                    .chat-messages-wrapper {
                        flex: 1 1 auto;
                        min-width: 2px;
                        max-height: 100dvh;

                        .message-wrapper {
                            background-color: rgba(255, 255, 255, 0.7);
                        }
                    }

                    .expand-view-btn {
                        transform: rotate(180deg);
                    }
                }
            }

            .expand-view-btn {
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                z-index: 1;
                transition: transform 500ms ease-in-out;
                box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.5);

                &:focus,
                &:hover,
                &:active {
                    background-color: $success;
                }
            }

            .chat-messages-wrapper {
                position: relative;
                margin-bottom: 0.5rem;
                overflow: auto;
                border-radius: 1rem;
                transition: all 300ms ease-in-out;

                @include media-breakpoint-down(md) {
                    max-height: 30dvh;
                    padding-top: 35px;
                }

                .message-wrapper {
                    background-color: $dark-900;
                    width: fit-content;
                    margin: 6px 0;
                    border-radius: 1rem;
                    padding: 10px;
                    transition: background-color 300ms ease-in-out;
                    border: 1px solid $border-dark;

                    .message-content {
                        margin-bottom: 0;
                        word-break: break-word;

                        &:first-of-type {
                            display: inline;
                        }
                    }

                    .message-name {
                        font-weight: bold;
                        margin-right: 4px;
                        text-transform: capitalize;
                    }
                }
            }

            textarea {
                @include media-breakpoint-down(md) {
                    height: 60px;
                }
            }

        }

        .canvas-element {
            position: absolute;
            width: 100%;
            height: 100%;
            background: transparent;

            &:focus-visible {
                outline: none;
            }
        }
    }
}

.canvas-loader {
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    .spinner-border {
        --bs-spinner-width: 4rem;
        --bs-spinner-height: 4rem;
        --bs-spinner-border-width: 0.5em;
        --bs-spinner-animation-speed: 1s;
    }
}

.listing-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.action-btn {
    position: relative;
    min-width: 38px;
    max-width: 38px;
    height: 38px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled,
    &.disabled {
        border-color: transparent;
    }
}

.reset-view-btn {
    position: absolute;
    z-index: 1;

    @include media-breakpoint-down(md) {
        top: 1rem;
        right: 1rem;
    }

    @include media-breakpoint-up(md) {
        bottom: 1rem;
        left: 1rem;
    }
}

#avaturn-sdk-container {
    width: 100%;
    height: 100vh;
    border: none;
}

.avatar-nav-btn {
    &.active {
        @extend .btn-primary;
        @extend .rounded-2;
    }

    &:focus {
        @extend .rounded-2;
    }
}

.reset-view-btn {
    .btn {
        @include media-breakpoint-down(md) {
            width: 42px;
            height: 42px
        }
    }
}

.custom-card {
    border-radius: var(--common-border-radius);
    border-color: $border-dark;
    background-color: $dark-800;
    color: $light;

    .card-header,
    .card-footer {
        background-color: $dark-800;
        border-color: $border-dark;
        padding: 1rem 1.5rem;
    }

    .card-header {
        border-radius: var(--common-border-radius) var(--common-border-radius) 0 0;
    }

    .card-body {
        padding: 1.5rem;
    }

    .card-footer {
        border-radius: 0 0 var(--common-border-radius) var(--common-border-radius);
    }

    .stretched-link {
        &:after {
            border: 1px solid transparent;
            border-radius: var(--common-border-radius);
            transition: border-color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &:hover {
            &:after {
                border-color: rgba(106, 84, 193, 0.5);
            }
        }
    }

    .card-count {
        transition: color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:hover {
        .card-count {
            color: $primary;
        }
    }
}

.color-input__picker {
    z-index: 1056;
}

.loader {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1000000;

    svg {
        animation: spin 1250ms linear infinite;
        height: 35px;
        width: 35px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// will not work inside iframe
@media screen and (max-width: 1024px) {
    .scene {
        height: calc(100% - 300px);
    }
}

@media (max-height: 575.98px) {
    .modal-dialog-scrollable .modal-content {
        overflow: unset;
    }
}

.layout-wrapper {
    display: flex;
    align-items: stretch;
    height: 100dvh;
    overflow: hidden;
}

.header {
    min-height: var(--header-height);
    padding: var(--common-padding);
    display: flex;
    align-items: center;
    // position: sticky;
    // top: 0;
    // z-index: 8;
    // @extend .bg-light;

    .toggle-sidebar-btn {
        transition: 300ms transform ease-in-out;
        border: none;
        position: relative;
        left: 0;

        @media (hover:hover) {
            svg {
                transition: 300ms transform ease;

                &:hover {
                    animation: indicator 1s ease-out infinite;
                }
            }
        }
    }
}

@keyframes indicator {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-2px);
    }

    50% {
        transform: translateX(0);
    }

    75% {
        transform: translateX(2px);
    }

    100% {
        transform: translateX(0);
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    background-color: $dark-800;
    position: relative;
    transition: width 150ms ease-in-out, left 150ms ease-in-out;
    width: var(--sidebar-width);
    @extend .shadow;

    .sidebar-header {
        padding: var(--common-padding);
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--header-height);

        .sidebar-logo {
            display: flex;
            flex-wrap: nowrap;
            color: #fff;
        }
    }

    .sidebar-top {
        border-top: 1px solid #dee2e6;
    }

    .sidebar-footer {
        padding: var(--common-padding);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .sidebar-menu-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        text-decoration: none;
        padding: 0.5rem 1rem;
        height: var(--control-height);
        width: 100%;

        &.dropdown-toggle {
            gap: 0.75rem;

            &::after {
                transform: rotate(90deg)
            }
        }

        &~.dropdown-menu {
            width: 100%;
        }
    }

    .sidebar-menu-title {
        @extend .text-truncate;
        color: #fff;
    }

    .sidebar-menu-icon {
        color: #fff;
    }

    .sidebar-menu-wrapper {
        @extend .flex-fill;
        padding: 1.5rem 0;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;

        .sidebar-menu-item {
            .sidebar-menu-link {
                transition: 150ms background-color ease-in-out;

                &:hover,
                &:active {
                    background-color: lighten($color: $primary, $amount: 4);
                }

                &:focus {
                    background-color: lighten($color: $primary, $amount: 7);
                }
            }

            &.active {
                .sidebar-menu-link {
                    background-color: $primary;
                }
            }
        }
    }

    .btn-close-sidebar {
        display: none;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: var(--round-btn-position);
        width: var(--round-btn-size);
        height: var(--round-btn-size);
        align-items: center;
        justify-content: center;
        padding: 2px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
    }

    &.opened {}

    &.mobile {
        width: var(--sidebar-mobile-width);
        position: absolute;
        left: 0;
        z-index: 99;

        .sidebar-logo {
            svg:not(:first-child) {
                display: inline;
            }
        }

        .btn-close-sidebar {
            display: flex;
        }

        &~.content-wrapper {
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100dvh;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 98;
            }
        }
    }

    &.collapsed {
        position: absolute;
        left: calc(-1 * var(--sidebar-width));
        z-index: 99;

        @media (hover:hover) {
            left: calc(-1 * var(--sidebar-width) + 2px);

            &:hover {
                left: 0;
            }
        }

        &~.content-wrapper {
            .toggle-sidebar-btn {
                transform: rotateY(180deg);
            }
        }
    }

    &.shrinked {
        width: var(--sidebar-shrink-width);


        .sidebar-logo {
            svg:not(:first-child) {
                display: none;
            }
        }

        .sidebar-menu-link {
            justify-content: center;

            &.dropdown-toggle {
                &::after {
                    display: none;
                }
            }
        }

        @media (hover:hover) {
            &:hover {
                position: absolute;
                width: var(--sidebar-width);
                z-index: 99;

                .sidebar-logo {
                    svg:not(:first-child) {
                        display: inline;
                    }
                }

                .sidebar-menu-link {
                    justify-content: space-between;

                    &.dropdown-toggle {
                        &::after {
                            display: inline;
                        }
                    }
                }

                .sidebar-menu-title {
                    display: inline;
                }

                &~.content-wrapper {
                    margin-left: var(--sidebar-shrink-width);
                }
            }
        }

        .sidebar-header,
        .sidebar-footer {
            padding: 1rem 0.5rem;
        }

        .sidebar-menu-wrapper {
            padding: 1rem 0.5rem;
        }

        .sidebar-menu-title {
            display: none;
        }
    }
}

.content-wrapper {
    @extend .flex-fill;
    display: flex;
    flex-direction: column;
    height: 100dvh;
    overflow-y: auto;

    .content-page {
        @extend .flex-fill;
        padding: var(--common-padding);
        position: relative;
    }
}

.toast {
    border: none;
}


// this class should be added with App class in App.tsx for rounded and spaced ui
.density-1 {
    --control-height: 54px;
    --common-padding: 1.5rem 2rem;
    --common-border-radius: 0.75rem;
    --pagination-btn: 40px;
    --common-btn-padding: 0.75rem 1.25rem;
    --common-font-size: 16px;
    --bs-border-radius: var(--common-border-radius);

    .gap-1 {
        gap: 0.5rem !important;
    }

    .gap-2 {
        gap: 1rem !important;
    }

    .gap-3 {
        gap: 1.5rem !important;
    }

    input[type=file].custom-form-control {
        padding-top: 14px;
        padding-left: 20px;
    }
}

.density-2 {
    --control-height: 48px;
    --common-padding: 1.25rem 1.75rem;
    --common-border-radius: 0.5rem;
    --pagination-btn: 36px;
    --common-btn-padding: 0.7rem 1rem;
    --common-font-size: 16px;
    --bs-border-radius: var(--common-border-radius);

    input[type=file].custom-form-control {
        padding-top: 11px;
        padding-left: 17px;
    }
}

.custom-accordion {
    .accordion-header {
        padding-top: 1px;
    }

    .accordion-button {
        min-height: var(--control-height);
        padding: $input-padding-y $input-padding-x;
        color: $light;
        background-color: $dark-800;
        box-shadow: none;
        border-color: $border-dark;

        &::after {
            content: none;
        }

        &:not(.collapsed) {
            border-bottom: 1px solid $border-dark;
        }
    }

    .accordion-body {
        content-visibility: auto;
    }


    .accordion-item {
        color: $light;
        background-color: $dark-900;
        border-color: $border-dark;
    }
}

.layout-density {
    .sidebar-menu-link {

        &.dropdown-toggle {
            &:after {
                content: none;
            }
        }
    }
}